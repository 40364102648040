import {
  DOCUMENT_SCOPE,
  IClient as IBEClient,
  IFolderTable,
} from "fieldpro-tools/dist/src/types";

import { ITableRowContent } from "model/application/components";

import {
  IHierarchyDependency,
  IMetaHierarchyDependency,
} from "./HierarchyDependency";
export {
  BILLING_TYPE,
  CLIENT_CATEGORY,
  CLIENT_REGIONS,
  CLIENT_SECTORS,
  CLIENT_STATUS,
  INVOICED_BY,
  TERRITORY_SCOPE,
} from "fieldpro-tools/dist/src/types";
export type { IAccessRightProfile } from "fieldpro-tools/dist/src/types/clients";

export interface IDashboardFolder {
  id: string;
  name: string;
  description: string;
}

export interface IDocumentFolder {
  id: string;
  name: string;
  description: string;
  scope: DOCUMENT_SCOPE;
}

export type TLightClient = Pick<
  IBEClient,
  | "id"
  | "name"
  | "description"
  | "active"
  | "dbname"
  | "status"
  | "app_user_licenses"
  | "workspace_type"
  | "billing_type"
>;

export interface IClient
  extends Omit<
    IBEClient,
    | "levels"
    | "gps_tracking_days"
    | "meta_hierarchy_dependencies"
    | "hierarchy_dependencies"
    | "sensible_data_password"
    | "mobile_user_business_roles"
    | "web_user_business_roles"
  > {
  gps_tracking_days: string[];
  profile: string;
  meta_hierarchy_dependencies: IMetaHierarchyDependency;
  hierarchy_dependencies: IHierarchyDependency[];
  go_back_days_filter: number;
  private_key: string;
  dataset_name: string;
  client_email: string;
  mobile_user_business_roles: { key: string; label: string }[]; // ignore the "from_template" property (managed in the backend only)
  web_user_business_roles: { key: string; label: string }[]; // ignore the "from_template" property (managed in the backend only)
}
export interface IFolder
  extends Omit<
    IFolderTable,
    "created_at" | "created_by" | "edited_at" | "edited_by"
  > {}

export interface IClientInTable extends ITableRowContent {
  active: boolean;
  name: string;
  description: string;
  app_user_licenses: number;
  dbname: string;
}

/**
 * @CLEAR - Clear, should be handled in the FE, will usually clear the filter value
 * @NEVER - Never, will sent a __null to the BE, which will look for items with a null date
 * @TODAY - Filter by today's date
 * @YESTERDAY - Filter by yesterday's date
 * @LAST_7_DAYS - Filter by the last 7 days
 * @THIS_WEEK - Filter by the current week
 * @LAST_WEEK - Filter by the previous week
 * @LAST_30_DAYS - Filter by the last 30 days
 * @THIS_MONTH - Filter by the current month
 * @LAST_MONTH - Filter by the previous month
 */
export enum DEFAULT_DATE_FILTER {
  CLEAR = "__CLEAR",
  NULL = "__NULL",
  TODAY = "TODAY",
  YESTERDAY = "YESTERDAY",
  LAST_7_DAYS = "LAST_7_DAYS",
  THIS_WEEK = "THIS_WEEK",
  LAST_WEEK = "LAST_WEEK",
  LAST_30_DAYS = "LAST_30_DAYS",
  THIS_MONTH = "THIS_MONTH",
  LAST_MONTH = "LAST_MONTH",
}

export enum NUMBER_FILTER {
  GREATER_THAN = "GREATER_THAN",
  GREATER_THAN_OR_EQUAL = "GREATER_THAN_OR_EQUAL",
  LESS_THAN = "LESS_THAN",
  LESS_THAN_OR_EQUAL = "LESS_THAN_OR_EQUAL",
  IS = "IS",
  IS_NOT = "IS_NOT",
}

export interface IInvoice {
  id: string;
  stripe_customer_id: string;
  description?: string;
  url: string;
  created_at?: Date;
  currency?: string;
  status: string;
  total: number;
  total_excluding_tax?: number;
}
