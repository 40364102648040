import { useMemo } from "react";

import _ from "lodash";
import { MapboxMap } from "react-map-gl";

import * as colors from "assets/colors";
import Line from "assets/images/png/lines.png";
import { useMapImage } from "hooks/useMapImage";

import { ICustomerLocation } from "../subcategories/map/CustomersMap/CustomersMap";
import { DELIM_PATTERN_ID } from "../subcategories/map/CustomersMap/layers/customers";

interface IUseGeoDelimitationsCollection {
  customerLocations: ICustomerLocation[];
  customerGeoAttributesToDisplay: Record<string, boolean>;
  map?: MapboxMap;
}
const useGeoDelimitationsCollection = ({
  customerLocations,
  customerGeoAttributesToDisplay,
  map,
}: IUseGeoDelimitationsCollection) => {
  const baseColors = [
    colors.teal,
    colors.warningColor,
    colors.purplePzazz,
    colors.blue,
  ];
  const extraColors = _.without(
    _.compact(_.flatten(_.values(colors))),
    ...baseColors
  );
  const layerColors = [...baseColors, ...extraColors];

  useMapImage({
    map,
    path: Line,
    name: DELIM_PATTERN_ID,
    sdf: false,
  });

  const customersGeoLayers = useMemo(
    () =>
      _.compact(
        _.flatMap(
          Object.entries(customerGeoAttributesToDisplay),
          ([attr, display], index) => {
            return _.map(customerLocations, (customer) => {
              const layer = _.get(customer, attr);

              if (!display || !layer) return null;

              const color = layerColors[index];

              _.set(layer, "properties.color", color);

              return layer;
            });
          }
        )
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customerGeoAttributesToDisplay, JSON.stringify(customerLocations)]
  );

  const customersGeoLayerFeatureCollection = {
    type: "FeatureCollection" as const,
    features: customersGeoLayers as any,
  };

  return { customersGeoLayerFeatureCollection };
};

export default useGeoDelimitationsCollection;
