import React, { useState } from "react";

import { Box } from "@material-ui/core";
import {
  LANGUAGE,
  TIMEZONE_LABEL,
} from "fieldpro-tools/dist/src/types/clients";
import { useSelector } from "react-redux";

import FormFieldBackground from "components/Forms/Form/FormField/FormFieldBackground";
import FormSection from "components/Forms/Form/Section/FormSection";
import InputBoolean from "components/Input/InputBoolean";
import InputImage from "components/Input/InputImage";
import InputMultipleSelect from "components/Input/InputMultipleSelect";
import InputSelect from "components/Input/InputSelect";
import InputText, {
  INPUT_TEXT_TYPE,
} from "components/Input/InputText/InputText";
import InputTime from "components/Input/InputTime";
import { WORKSPACE_TYPES } from "containers/clients/model";
import { getSelectedClient } from "containers/clients/redux/selectors";
import WorkspaceSelector from "containers/clients/WorkspaceSelector";
import useTranslations from "hooks/useTranslations";
import { IOption } from "model/application/components";
import { TViewMode } from "model/application/modal/CreateEditModal";
import { IClient } from "model/entities/Client";

export interface IMainTabProps extends Partial<IClient> {
  viewMode?: TViewMode;
  viewOnly?: boolean;
  onInputChange: any;
  onChangePictures: any;
  onSelectChange: any;
  onChangeGpsTrackingDays: any;
  getFieldError: (key: string) => string | undefined;
  onSave?: () => void;
  onDiscard?: () => void;
  onCreate?: () => void;
}

export const MainTab: React.FunctionComponent<IMainTabProps> = ({
  viewMode = "CREATE",
  viewOnly,
  name,
  description,
  timezone,
  id,
  logo,
  onInputChange,
  onChangePictures,
  onSelectChange,
  onChangeGpsTrackingDays,
  getFieldError,
  gps_tracking_days,
  time_slots_start,
  is_gps_tracking_enabled,
  geo_checkin_distance,
  time_slots_stop,
  workspace_type,
  language,
  onSave,
  onDiscard,
  onCreate,
}) => {
  const client = useSelector(getSelectedClient);
  const lang = useTranslations();
  const [isEditing, setEditing] = useState(false);
  const planningLangKey =
    lang.containers.calendar.subCategories.calendar.createEditModal
      .planningConfig;
  const label = lang.containers.clients.subCategories.clients.createEditModal;

  const toggleIsEditing = () => {
    setEditing((isEditing) => !isEditing);
  };

  const onSaveWrapped =
    onSave &&
    (() => {
      onSave();
      toggleIsEditing();
    });

  const onDiscardWrapped =
    onDiscard &&
    (() => {
      onDiscard();
      toggleIsEditing();
    });

  const workspaceOptions: IOption<WORKSPACE_TYPES>[] = [
    {
      key: WORKSPACE_TYPES.CUSTOM,
      label: "CUSTOM",
    },
    {
      key: WORKSPACE_TYPES.TEMPLATE,
      label: "TEMPLATE",
    },
  ];
  const [selectedWorkspace, setSelectedWorkspace] = useState<WORKSPACE_TYPES>(
    WORKSPACE_TYPES.CUSTOM
  );

  const handleChangeWorkspaceType = (opt: WORKSPACE_TYPES, name: string) => {
    if (opt === WORKSPACE_TYPES.CUSTOM) {
      onSelectChange(opt, name);
      setSelectedWorkspace(opt);
    } else {
      setSelectedWorkspace(opt);
    }
  };

  const buildGPSTrackerConfigs = ({ viewMode }: any) => {
    const allDaysOptions = ["mo", "tu", "we", "th", "fr", "sa", "su"];
    return (
      <FormFieldBackground viewMode={viewMode}>
        <InputMultipleSelect
          viewMode={viewMode}
          name="gps_tracking_days"
          defaultValue={(gps_tracking_days ?? []).map((m) => ({
            key: m,
            label: label.inputTrackingDays.options[m],
          }))}
          options={allDaysOptions.map((k) => ({
            key: k,
            label: label.inputTrackingDays.options[k],
          }))}
          onChange={onChangeGpsTrackingDays}
          multipleSelection
          langlabel={label.inputTrackingDays}
          lang={lang}
        />
      </FormFieldBackground>
    );
  };

  const buildGPSTrackerTimeConfigs = ({ viewMode }: any) => {
    return (
      <>
        <InputTime
          viewMode={viewMode}
          defaultValue={time_slots_start}
          name="time_slots_start"
          lang={label.inputTimeSlotsStart}
          onChange={onInputChange}
          required={false}
          clearable
          inLocalTime={false}
        />
        <FormFieldBackground viewMode={viewMode}>
          <InputTime
            viewMode={viewMode}
            defaultValue={time_slots_stop}
            name="time_slots_stop"
            lang={label.inputTimeSlotsStop}
            onChange={onInputChange}
            required={false}
            clearable
            inLocalTime={false}
          />
        </FormFieldBackground>
      </>
    );
  };
  const buildGeoCheckInDistanceConfigs = ({ viewMode }: any) => {
    return (
      <>
        <FormFieldBackground viewMode={viewMode}>
          <InputText
            defaultValue={geo_checkin_distance}
            error={getFieldError("geo_checkin_distance")}
            name="geo_checkin_distance"
            key={`geo_checkin_distance${id}`}
            lang={label.inputGeoCheckinDistance}
            onChange={onInputChange}
            required={true}
            type={INPUT_TEXT_TYPE.INTEGER}
            viewMode={viewMode}
            debounceDuration={400}
          />
        </FormFieldBackground>
      </>
    );
  };
  if (viewMode === "CREATE") {
    return (
      <>
        <FormSection
          saveText={label.inputMainTab.title}
          onSave={onCreate}
          onDiscard={onDiscard}
          isCreation
        >
          {/* Select language */}
          <InputSelect
            viewMode={viewMode}
            title={lang.mainLayout.selectedLang}
            name={"language"}
            options={[
              { key: LANGUAGE.en, label: lang.mainLayout.englishLanguage },
              { key: LANGUAGE.fr, label: lang.mainLayout.frenchLanguage },
              { key: LANGUAGE.es, label: lang.mainLayout.espagnolLanguage },
            ]}
            value={language}
            onChange={onSelectChange}
          />
          <Box data-testid="workspace-type-selector">
            <InputSelect
              viewMode={viewMode}
              lang={label.inputTemplateType}
              name={"workspace_type"}
              options={workspaceOptions}
              value={selectedWorkspace}
              onChange={handleChangeWorkspaceType}
            />
          </Box>

          {selectedWorkspace === WORKSPACE_TYPES.TEMPLATE ? (
            <FormFieldBackground viewMode={viewMode}>
              <WorkspaceSelector
                viewMode={viewMode}
                selectedWorkspace={
                  workspace_type ?? label.inputTemplateType.options.custom
                }
                onChange={onSelectChange}
              />
            </FormFieldBackground>
          ) : null}

          {id ? (
            <InputText
              // viewMode={viewMode}
              disabled
              defaultValue={id}
              error={getFieldError("id")}
              name="id"
              key={`id${id}`}
              lang={label.inputMainTabId}
              onChange={onInputChange}
              viewMode={"VIEW"}
              replaceSpaces
              lowercase
              debounceDuration={400}
            />
          ) : null}

          <InputText
            defaultValue={name}
            error={getFieldError("name")}
            name="name"
            key={`name${id}`}
            lang={label.inputMainTabName}
            onChange={onInputChange}
            required={true}
            viewMode={viewMode}
            debounceDuration={400}
          />

          <InputText
            defaultValue={description}
            name="description"
            key={`description${id}`}
            lang={label.inputMainTabDescription}
            onChange={onInputChange}
            viewMode={viewMode}
            debounceDuration={400}
          />

          <InputSelect
            viewMode={viewMode}
            value={timezone}
            options={Object.keys(TIMEZONE_LABEL).map((timezone) => ({
              key: timezone,
              label: TIMEZONE_LABEL[timezone],
            }))}
            error={getFieldError("timezone")}
            name="timezone"
            key={`timezone${id}${timezone}`}
            lang={label.inputMainTabTimezone}
            onChange={onSelectChange}
            required={true}
          />
          <InputBoolean
            defaultValue={is_gps_tracking_enabled}
            name="is_gps_tracking_enabled"
            key={`is_gps_tracking_enabled${id}`}
            lang={label.inputGpsTrackingEnabled}
            onChange={onInputChange}
            required={false}
            viewMode={viewMode}
          />
          {is_gps_tracking_enabled && (
            <>
              {buildGeoCheckInDistanceConfigs("CREATE")}

              {buildGPSTrackerConfigs("CREATE")}

              {buildGPSTrackerTimeConfigs("CREATE")}
            </>
          )}

          <InputImage
            lang={label.inputLogo}
            name={"logo"}
            defaultValue={logo}
            onChange={onChangePictures}
            required={false}
            viewMode={viewMode}
          />
        </FormSection>
      </>
    );
  }

  // viewMode = "VIEW" / "EDIT"
  // (switching has no effect, viewMode handled internally by the FormSections);
  return (
    <>
      <FormSection
        onSave={onSaveWrapped}
        onDiscard={onDiscardWrapped}
        onEdit={toggleIsEditing}
        paperTitle={label.clientsDetails.title}
        paperDescription={label.clientsDetails.description}
        disableEdit={isEditing}
        viewOnly={viewOnly}
      >
        {({ viewMode }) => (
          <>
            {/* Select language */}
            <InputSelect
              viewMode={viewMode}
              title={lang.mainLayout.selectedLang}
              name={"language"}
              options={[
                { key: LANGUAGE.en, label: lang.mainLayout.englishLanguage },
                { key: LANGUAGE.fr, label: lang.mainLayout.frenchLanguage },
                { key: LANGUAGE.es, label: lang.mainLayout.espagnolLanguage },
              ]}
              value={language && language.length > 0 ? language : LANGUAGE.en}
              onChange={onSelectChange}
            />

            <FormFieldBackground viewMode={viewMode}>
              <WorkspaceSelector
                viewMode={viewMode}
                selectedWorkspace={workspace_type ? workspace_type : "Custom"}
                onChange={onSelectChange}
                disabled
              />
            </FormFieldBackground>
            {id ? (
              <InputText
                // viewMode={viewMode}
                disabled
                defaultValue={id}
                error={getFieldError("id")}
                name="id"
                key={`id${id}`}
                lang={label.inputMainTabId}
                onChange={onInputChange}
                viewMode={"VIEW"}
                replaceSpaces
                lowercase
                debounceDuration={400}
              />
            ) : null}

            <FormFieldBackground viewMode={viewMode}>
              <InputText
                defaultValue={name}
                error={getFieldError("name")}
                name="name"
                key={`name${id}`}
                lang={label.inputMainTabName}
                onChange={onInputChange}
                required={true}
                viewMode={viewMode}
                debounceDuration={400}
              />
            </FormFieldBackground>

            <InputText
              defaultValue={description}
              name="description"
              key={`description${id}`}
              lang={label.inputMainTabDescription}
              onChange={onInputChange}
              viewMode={viewMode}
            />

            <InputSelect
              viewMode={viewMode}
              value={timezone}
              options={Object.keys(TIMEZONE_LABEL).map((timezone) => ({
                key: timezone,
                label: TIMEZONE_LABEL[timezone],
              }))}
              error={getFieldError("timezone")}
              name="timezone"
              key={`timezone${id}${timezone}`}
              lang={label.inputMainTabTimezone}
              onChange={onSelectChange}
              required={true}
            />

            <FormFieldBackground viewMode={viewMode}>
              <InputImage
                lang={label.inputMainTabLogo}
                name={"logo"}
                defaultValue={logo}
                onChange={onChangePictures}
                required={false}
                viewMode={viewMode}
              />
            </FormFieldBackground>
          </>
        )}
      </FormSection>

      <FormSection
        onSave={onSaveWrapped}
        onDiscard={onDiscardWrapped}
        onEdit={toggleIsEditing}
        paperTitle={label.clientsGPSTrackerTime.title}
        paperDescription={label.clientsGPSTrackerTime.description}
        disableEdit={isEditing}
        viewOnly={viewOnly}
      >
        {({ viewMode }) => (
          <>
            <InputBoolean
              defaultValue={is_gps_tracking_enabled}
              name="is_gps_tracking_enabled"
              key={`is_gps_tracking_enabled${id}`}
              lang={label.inputGpsTrackingEnabled}
              onChange={onInputChange}
              required={false}
              viewMode={viewMode}
            />
            {is_gps_tracking_enabled
              ? buildGeoCheckInDistanceConfigs({ viewMode })
              : null}
            {is_gps_tracking_enabled
              ? buildGPSTrackerConfigs({ viewMode })
              : null}
            {is_gps_tracking_enabled
              ? buildGPSTrackerTimeConfigs({ viewMode })
              : null}
          </>
        )}
      </FormSection>
      {client.enable_visit_planning && (
        <FormSection
          onSave={onSaveWrapped}
          onDiscard={onDiscardWrapped}
          onEdit={toggleIsEditing}
          paperTitle={planningLangKey.title}
          paperDescription={
            planningLangKey.customMessage.planningConfigDescription
          }
          disableEdit={isEditing}
          viewOnly={viewOnly}
        >
          {({ viewMode }) => (
            <>
              <InputBoolean
                defaultValue={client?.enable_visit_planning_weekends}
                onChange={onInputChange}
                viewMode={viewMode}
                lang={{
                  title: planningLangKey.customMessage.displayWeekends,
                }}
                tooltip={
                  planningLangKey.customMessage.displayWeekendsDescription
                }
                name="enable_visit_planning_weekends"
              />
              <InputBoolean
                defaultValue={client?.calendar_validation_enabled}
                onChange={onInputChange}
                viewMode={viewMode}
                lang={{
                  title:
                    planningLangKey.customMessage.calendarValidationEnabled,
                }}
                tooltip={
                  planningLangKey.customMessage
                    .calendarValidationEnabledDescription
                }
                name="calendar_validation_enabled"
              />
            </>
          )}
        </FormSection>
      )}
    </>
  );
};

export default MainTab;
