import { useEffect, useState } from "react";

import { Box, Button, makeStyles } from "@material-ui/core";
import {
  CalendarEventRepetitionType,
  IVisitEvent,
  MOMENT_TIMEZONES,
  TCalendarEvent,
} from "fieldpro-tools";
import _ from "lodash";
import moment from "moment";
import { useSelector } from "react-redux";

import { red, StrokeLowEnphasis } from "assets/colors";
import {
  BasicCloseIcon,
  BasicDialogActions,
  BasicDialogContent,
  BasicDialogTitle,
} from "components/Dialog/BasicDialog";
import InputDate from "components/Input/InputDate";
import InputMultipleSelect from "components/Input/InputMultipleSelect";
import InputSelect from "components/Input/InputSelect";
import InputText, {
  INPUT_TEXT_TYPE,
} from "components/Input/InputText/InputText";
import InputTime from "components/Input/InputTime";
import { getSelectedClient } from "containers/clients/redux/selectors";
import useFormState from "hooks/useFormState";
import useTranslations from "hooks/useTranslations";
import { IOption } from "model/application/components";
import { TViewMode } from "model/application/modal/CreateEditModal";
import { isValidDate } from "utils/dateUtils";

import { TNewEventPayload } from "../redux/types";
import {
  getDurationOptions,
  getRecurrenceOptions,
} from "../utils/getDurationOptions";
import { getErrorMessagesForVisitDialog } from "../utils/getErrorMessagesForVisitDialog";
import { getCalendarWorkingHours } from "./Calendar/utils/getTimesOfDay";

const useStyles = makeStyles(() => ({
  drawer: {
    width: 447,
    left: "unset",
    right: 10,
    height: "calc(100vh - 100px)",
    padding: "16px",
    display: "grid",
  },
  header: {
    display: "flex",
    width: "100%",
    alignItems: "center",
  },
  title: {
    flexGrow: 2,
    fontSize: "16px",
    fontWeight: "bold",
  },
  content: {
    flexGrow: 2,
  },
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    alignItems: "center",
    gridColumnGap: "16px",
  },
  footer: {
    background: StrokeLowEnphasis,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "8px 16px",
    margin: "0 -16px",
  },
  cancelButton: {
    color: red,
  },
}));

export interface IAddVisitDialogProps {
  onChange?: (event: TNewEventPayload) => void;
  events: IVisitEvent[];
  initEvent?: TNewEventPayload;
  onAddVisit: (event: TNewEventPayload) => void;
  onEditEvent?: (event: TNewEventPayload) => void;
  handleClose: () => void;
  customerOptions: IOption<string>[];
  viewMode?: TViewMode;
  children?: React.ReactNode;
}

function AddVisitDialog({
  events,
  onChange,
  initEvent,
  onAddVisit,
  handleClose,
  onEditEvent,
  customerOptions,
  viewMode,
  children,
}: Readonly<IAddVisitDialogProps>) {
  const client = useSelector(getSelectedClient);
  const lang = useTranslations();
  const classes = useStyles();
  const [duration, setDuration] = useState<number>(
    getDefaultDuration(initEvent || {})
  );
  const [invalidDate, setInvalidDate] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const { minEventStartHour, maxEventStartHour } =
    getCalendarWorkingHours(client);
  const { attributes, handleInputChange, errors } =
    useFormState<TNewEventPayload>({
      initAttributes: {
        start_time: new Date(),
        ...initEvent,
      },
      validate: ({ attributes }) =>
        getErrorMessagesForVisitDialog({
          attributes,
          lang,
          additionnalProps: {
            events,
            maxEventStartHour,
            minEventStartHour,
          },
        }),
    });
  const debouncedHandleInputChange = _.debounce(handleInputChange, 500);
  const { start_time } = attributes;

  function handleChangeDuration(value: string) {
    setDuration(parseInt(value));
  }
  function handleChangeRepetition(value: CalendarEventRepetitionType) {
    const repetionObject: TCalendarEvent["repetition"] = {
      repeat_on: [],
      type: value,
      type_iteration: 0,
    };
    handleInputChange(repetionObject, "repetition");
  }

  function handleSave() {
    setShowErrors(true);
    if (invalidDate) return;
    if (!_.isEmpty(errors)) return;

    if (viewMode === "CREATE") {
      onAddVisit(attributes);
    }
    if (viewMode === "EDIT" && onEditEvent) {
      onEditEvent(attributes);
    }

    handleClose();
  }

  useEffect(() => {
    const end_time = moment.utc(start_time).add(duration, "minutes").toDate();
    handleInputChange(end_time, "end_time");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start_time, duration]);

  function getError(field: string) {
    if (!errors || !showErrors) return null;
    return errors[field];
  }

  const langKey = lang.containers.calendar.subCategories.calendar;

  useEffect(() => {
    handleInputChange(initEvent?.assigned_to, "assigned_to");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initEvent?.assigned_to]);

  useEffect(() => {
    if (onChange) {
      onChange(attributes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attributes]);

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      height={"100%"}
      data-testid="add-visit-dialog"
    >
      <BasicDialogTitle>
        <Box className={classes.header}>
          <Box className={classes.title}>
            <b>
              {viewMode === "CREATE"
                ? langKey.createEditModal.inputVisit.title
                : langKey.createEditModal.inputVisit.customMessage.editVisit}
            </b>
          </Box>
          <Box>
            <BasicCloseIcon onClick={handleClose} />
          </Box>
        </Box>
      </BasicDialogTitle>
      <BasicDialogContent>
        {children}
        <InputMultipleSelect
          dataTestId="customer_selection"
          error={getError("customer_id")}
          lang={lang}
          langlabel={{
            title:
              lang.containers.lists.subCategories.lists.createEditModal
                .inputListType.options.CUSTOMER,
            tooltip: "",
          }}
          name="customer_id"
          options={customerOptions}
          useDropDownMenu={true}
          multipleSelection={false}
          onChange={(customer) => {
            handleInputChange(_.get(customer, "0.key"), "customer_id");
            handleInputChange(_.get(customer, "0.label"), "title");
          }}
          defaultValue={_.filter(customerOptions, {
            key: attributes.customer_id,
          })}
        />
        <InputDate
          error={getError("start_time")}
          name="start_time"
          onChange={(value, name) => {
            const dateIsValid = isValidDate(value);
            setInvalidDate(!dateIsValid);
            if (dateIsValid) {
              const updatedValue = moment(value)
                .hour(moment(start_time).hour())
                .minute(moment(start_time).minute())
                .toDate();
              debouncedHandleInputChange(updatedValue, name);
            }
          }}
          defaultValue={start_time}
          lang={{
            title: "Date",
            tooltip: "",
          }}
        />
        <Box className={classes.gridContainer}>
          <Box>
            <InputTime
              lang={{
                title:
                  lang.containers.dashboards.subCategories.dashboards
                    .createEditModal.inputAxisType.options.TIME,
                tooltip: "",
              }}
              name="start_time"
              onChange={debouncedHandleInputChange}
              defaultValue={start_time}
              timezone={MOMENT_TIMEZONES["Etc/Universal"]}
              error={getError("select_time")}
            />
          </Box>
          <Box>
            <InputSelect
              error={getError("duration")}
              name="duration"
              onChange={handleChangeDuration}
              value={duration.toString()}
              lang={{
                title: langKey.createEditModal.inputVisit.options.duration,
                tooltip: "",
              }}
              options={getDurationOptions(lang)}
              dataTestId="duration"
            />
          </Box>
        </Box>
        <InputSelect
          error={getError("repetition")}
          name="repetition"
          onChange={handleChangeRepetition}
          value={
            attributes?.repetition?.type || CalendarEventRepetitionType.NEVER
          }
          lang={{
            title: langKey.createEditModal.inputVisit.options.repetition,
            tooltip: "",
          }}
          options={getRecurrenceOptions(lang)}
          dataTestId="repetition"
        />
        <Box>
          <InputText
            multiline
            error={getError("description")}
            name="description"
            onChange={debouncedHandleInputChange}
            defaultValue={attributes.description}
            lang={{
              title: langKey.createEditModal.inputVisit.options.description,
            }}
            type={INPUT_TEXT_TYPE.TEXT}
          />
        </Box>
      </BasicDialogContent>
      <BasicDialogActions>
        <Box display={"flex"} flexDirection={"row"} width={"100%"}>
          <Box flexGrow={2}>
            <Button
              className={classes.cancelButton}
              disableElevation
              onClick={handleClose}
            >
              {lang.genericTerms.cancel}
            </Button>
          </Box>
          <Box>
            <Button
              color="secondary"
              disableElevation
              variant="contained"
              onClick={handleSave}
            >
              {lang.genericTerms.save}
            </Button>
          </Box>
        </Box>
      </BasicDialogActions>
    </Box>
  );
}

export default AddVisitDialog;

export function getDefaultDuration({
  start_time,
  end_time,
}: Partial<IVisitEvent>) {
  if (end_time && start_time) {
    const duration = moment(end_time).diff(start_time, "minutes");
    return Math.ceil(duration / 30) * 30;
  }
  return 30;
}
