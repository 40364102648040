import { ComponentProps, useState } from "react";

export type TOnClickEvent = Parameters<
  NonNullable<ComponentProps<"rect">["onClick"]>
>[0];
export interface IDetectionRect
  extends Omit<ComponentProps<"rect">, "onClick"> {
  id: string;
  active?: boolean;
  fill?: string;
  stroke?: string;
  onHover?: (id: string) => void;
  onHoverEnd?: (id: string) => void;
  onClick?: (id: string, event: TOnClickEvent) => void;
}

export default function DetectionRect({
  id,
  active,
  fill: fillColor,
  stroke: strokeColor,
  onHover,
  onHoverEnd,
  onClick,
  ...rest
}: IDetectionRect) {
  const [hover, setHover] = useState(false);

  function handleMouseEnter() {
    setHover(true);
    if (onHover) onHover(id);
  }
  function handleMouseLeave() {
    setHover(false);
    if (onHoverEnd) onHoverEnd(id);
  }
  function handleClick(event: TOnClickEvent) {
    if (onClick) onClick(id, event);
  }

  return (
    <rect
      fill={active || hover ? fillColor : "transparent"}
      stroke={active || hover ? strokeColor : "transparent"}
      strokeWidth="2"
      cursor={"pointer"}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      style={{
        transition: "0.3s",
      }}
      data-testid={`detection-rect-${id}`}
      {...rest}
    />
  );
}
