import { CUSTOM_FIELD_TYPE } from "fieldpro-tools";
import _ from "lodash";

import { getTagKey } from "components/Input/InputMatrix/utils/getQuestionColumns";
import { TABLE_COLUMN_TYPE, TColumnType } from "components/Table/model";
import TLang from "model/application/Lang";
import { IListSchema } from "model/entities/List";
import { IActivity, IQuestion } from "model/entities/Workflow";
import { activityNeedsCustomer } from "utils/businessUtils";

export const getActivityColumnTypes = (
  activity: IActivity,
  lang: TLang
): TColumnType[] => {
  if (!activity) {
    return [];
  }
  const defaultColumnTypes: TColumnType[] = [
    {
      name: "_displayed_name",
      label: "report title",
      type: TABLE_COLUMN_TYPE.TEXT,
      hidden: false,
    },
    {
      name: "_created_by",
      hidden: true,
      type: TABLE_COLUMN_TYPE.TEXT,
      label:
        lang.containers.workflows.subCategories.activityReports.columns
          .createdBy,
    },
    {
      name: "_user_name",
      hidden: false,
      label:
        lang.containers.workflows.subCategories.activityReports.columns
          .userName,
      type: TABLE_COLUMN_TYPE.TEXT,
    },
    {
      name: "_created_at",
      hidden: true,
      type: TABLE_COLUMN_TYPE.DATE_TIME,
      label:
        lang.containers.workflows.subCategories.activityReports.columns
          .createdAt,
    },
    {
      name: "_completed_at",
      hidden: false,
      type: TABLE_COLUMN_TYPE.DATE_TIME,
      label:
        lang.containers.workflows.subCategories.activityReports.columns
          .completedAt,
    },
  ];
  if (activityNeedsCustomer(activity)) {
    defaultColumnTypes.push({
      name: "customer_name",
      hidden: false,
      type: TABLE_COLUMN_TYPE.TEXT,
      label:
        lang.containers.workflows.subCategories.activityReports.columns
          .customerName,
    });
  }

  // Remove PLAIN_TEXT questions since they do not generate any answers and they are only there for display
  const questionToDisplay = _.reject(activity?.questionnaire.questions, {
    type: CUSTOM_FIELD_TYPE.PLAIN_TEXT,
  });

  const questionColumnTypes = _.map(questionToDisplay, (att) => ({
    name: att.tag,
    type: getColumnTypeFromAttrType(att.type),
    label: att.question_text,
    hidden:
      activity.important && activity.important.includes(att.tag) ? false : true,
  }));

  return _.concat(defaultColumnTypes, questionColumnTypes);
};

export const extractType = (
  tag: string,
  customFields: IQuestion[] | IListSchema[]
) => {
  if (!customFields) return "";
  const attr =
    customFields &&
    _.find(
      customFields,
      (att: IListSchema | IQuestion) => att[getTagKey(att)] === tag
    );
  return attr ? (attr as IQuestion | IListSchema).type : "";
};

export const getColumnTypeFromAttrType = (
  attrType: CUSTOM_FIELD_TYPE
): TABLE_COLUMN_TYPE => {
  switch (attrType) {
    case CUSTOM_FIELD_TYPE.BOOLEAN:
      return TABLE_COLUMN_TYPE.BOOLEAN;
    case CUSTOM_FIELD_TYPE.UPLOAD_FILE:
      return TABLE_COLUMN_TYPE.UPLOAD;
    case CUSTOM_FIELD_TYPE.GPS:
      return TABLE_COLUMN_TYPE.GPS;
    case CUSTOM_FIELD_TYPE.SIGNATURE:
      return TABLE_COLUMN_TYPE.SIGNATURE;
    case CUSTOM_FIELD_TYPE.PICTURE:
      return TABLE_COLUMN_TYPE.PICTURE;
    case CUSTOM_FIELD_TYPE.SINGLE_CHOICE:
      return TABLE_COLUMN_TYPE.SINGLE_CHOICE;
    case CUSTOM_FIELD_TYPE.MULTIPLE_CHOICE:
      return TABLE_COLUMN_TYPE.MULTIPLE_CHOICE;
    case CUSTOM_FIELD_TYPE.SINGLE_CHOICE_ON_LIST:
      return TABLE_COLUMN_TYPE.SINGLE_CHOICE_ON_LIST;
    case CUSTOM_FIELD_TYPE.MULTIPLE_CHOICE_ON_LIST:
      return TABLE_COLUMN_TYPE.MULTIPLE_CHOICE_ON_LIST;
    case CUSTOM_FIELD_TYPE.MULTIPLE_INPUT_TEXT:
      return TABLE_COLUMN_TYPE.MULTIPLE_INPUT_TEXT;
    case CUSTOM_FIELD_TYPE.MULTIPLE_INPUT_INTEGER:
      return TABLE_COLUMN_TYPE.MULTIPLE_INPUT_INTEGER;
    case CUSTOM_FIELD_TYPE.MULTIPLE_INPUT_DECIMAL:
      return TABLE_COLUMN_TYPE.MULTIPLE_INPUT_DECIMAL;
    case CUSTOM_FIELD_TYPE.MATRIX_ON_LIST:
      return TABLE_COLUMN_TYPE.MATRIX_ON_LIST;
    case CUSTOM_FIELD_TYPE.RATING:
      return TABLE_COLUMN_TYPE.RATING;
    case CUSTOM_FIELD_TYPE.INTEGER:
    case CUSTOM_FIELD_TYPE.DECIMAL:
    case CUSTOM_FIELD_TYPE.COMPUTE:
      return TABLE_COLUMN_TYPE.NUMBER;
    case CUSTOM_FIELD_TYPE.TEXT:
    case CUSTOM_FIELD_TYPE.BARCODE_SCANNER:
    case CUSTOM_FIELD_TYPE.PHONE_NUMBER:
      return TABLE_COLUMN_TYPE.TEXT;
    case CUSTOM_FIELD_TYPE.DATE_PICKER:
      return TABLE_COLUMN_TYPE.DATE;
    case CUSTOM_FIELD_TYPE.TIME_PICKER:
      return TABLE_COLUMN_TYPE.TIME;
    default:
      return TABLE_COLUMN_TYPE.TEXT;
  }
};
